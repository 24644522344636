import * as React from "react";
import {
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Alert,
  UncontrolledAccordion,
} from "reactstrap";
import strings from "@Core/Helpers/strings";

// Types.
import type { RequestState } from "@Data/Types";
import type { ViewerState } from "../ViewerTypes";
import type { LearningMaterialPage } from "@Data/Entities/LearningMaterialPageEntity";

// Features.
import Loader from "src/components/loader/loader";
import SassyImage from "@Presentation/Components/SassyImage/SassyImage";

// Static.
import styles from "../Viewer.module.css";
import "@Presentation/Assets/css/images.css";
import gradientSrc from "@Presentation/Assets/gradients/gradient.svg";

type Props = {
  onClickPage: (page: LearningMaterialPage) => void;
  materialPages: ViewerState["materialPages"];
  selectedMaterialPage: ViewerState["selectedMaterialPage"];
  materialChapters: ViewerState["materialChapters"];
} & RequestState;

/**
 * Renderer for the sidebar.
 *
 * @returns {JSX.Element}
 *
 * @author kashan-ahmad
 * @version 0.0.1
 */
export default function ViewerSidebarRenderer({
  status,
  message,
  onClickPage,
  materialPages,
  materialChapters,
  selectedMaterialPage,
}: Props): JSX.Element {
  console.log({
    materialPages,
    materialChapters,
  });
  // status is the request status of material pages
  return status === "erred" ? (
    <Alert color="danger" className="mb-0">
      {message || strings.DEFAULT_ERROR_MESSAGE}
    </Alert>
  ) : status === "loading" || materialChapters.status === "loading" ? (
    <Loader
      isInline
      className="h-100 d-flex justify-content-center align-items-center"
    />
  ) : materialPages.length === 0 ? (
    <Alert color="primary" className="mb-0">
      {strings.DEFAULT_EMPTY_MESSAGE}
    </Alert>
  ) : (
    // In case there are no chapters, we don't want to block the user. So we
    // show the pages.

    <div className="px-3 pb-3 m-0">
      {materialChapters.data.length === 0 ||
      materialChapters.status === "erred" ? (
        <ul className="pb-3 d-flex flex-column gap-3">
          {materialPages.map((page) => (
            <LearningMaterialViewerPage
              key={page.id}
              page={page}
              selectedMaterialPage={selectedMaterialPage}
              onClickPage={onClickPage}
            />
          ))}
        </ul>
      ) : (
        <div>
          <h3 aria-label="Chapters" className="lh-sm text-secondary fs-6 mb-3">
            Chapters:
          </h3>
          <UncontrolledAccordion
            open={`chapter-${materialChapters.data[0].id}`}
          >
            {materialChapters.data.map((chapter) => (
              <AccordionItem className="bg-transparent">
                <AccordionHeader targetId={`chapter-${chapter.id}`}>
                  {chapter.title}
                </AccordionHeader>
                <AccordionBody accordionId={`chapter-${chapter.id}`}>
                  <div
                    className={`px-2 pb-2 d-flex gap-2 ${styles["hide-when-compact"]} `}
                  >
                    <div
                      className={`w-100 thumbnail rounded ${styles.thumbnail}`}
                    >
                      <SassyImage
                        src={chapter.thumbnail}
                        fallbackImgSrc={gradientSrc}
                      />
                    </div>
                    <div
                      className={`fs-6 text-secondary text-truncate-3  ${styles.text}`}
                    >
                      <span className="d-block text-muted fs-8">
                        Description:
                      </span>
                      {chapter.description}
                    </div>
                  </div>

                  <h4 className="text-secondary fs-6 my-2">Pages:</h4>
                  {
                    // If there are no pages in the chapter.
                    materialPages.filter(
                      (page) => page.chapterID === chapter.id
                    ).length === 0 ? (
                      <div className="text-muted">
                        No pages in this chapter.
                      </div>
                    ) : (
                      <ul className="pb-3 d-flex flex-column gap-3">
                        {materialPages
                          .filter((page) => page.chapterID === chapter.id)
                          .map((page) => (
                            <LearningMaterialViewerPage
                              key={page.id}
                              page={page}
                              selectedMaterialPage={selectedMaterialPage}
                              onClickPage={onClickPage}
                            />
                          ))}
                      </ul>
                    )
                  }
                </AccordionBody>
              </AccordionItem>
            ))}
          </UncontrolledAccordion>
          {materialPages.find((page) => page.chapterID === null) && (
            <>
              <h3
                aria-label="Unorganized Pages"
                className="lh-sm text-secondary fs-6 my-3"
              >
                Pages without chapters:
              </h3>
              <ul className="pb-3 d-flex flex-column gap-3">
                {materialPages
                  .filter((page) => page.chapterID === null)
                  .map((page) => (
                    <LearningMaterialViewerPage
                      key={page.id}
                      page={page}
                      selectedMaterialPage={selectedMaterialPage}
                      onClickPage={onClickPage}
                    />
                  ))}
              </ul>
            </>
          )}
        </div>
      )}
    </div>
  );
}

function LearningMaterialViewerPage({
  page,
  selectedMaterialPage,
  onClickPage,
}: {
  page: LearningMaterialPage;
  selectedMaterialPage: LearningMaterialPage | undefined;
  onClickPage: (page: LearningMaterialPage) => void;
}): JSX.Element {
  return (
    <li>
      <button
        onClick={() => onClickPage(page)}
        className={`material-page p-2 w-100 bg-white border rounded focusable text-start overflow-hidden ${
          page.id == selectedMaterialPage?.id ? "border-secondary" : ""
        } `}
      >
        <div className="d-flex flex-nowrap gap-2 align-items-center">
          {/* Thumbnail. */}
          <div
            className={`w-100 flex-shrink-0 thumbnail rounded ${styles.thumbnail}`}
          >
            <SassyImage src={page.thumbnail} fallbackImgSrc={gradientSrc} />
          </div>
          {/* Textual Content. */}
          <div className={`fs-6 text-secondary ${styles.text}`}>
            <div aria-label="Title" className="lh-sm text-truncate-2">
              {page.title}
            </div>
            <div className="fs-7 text-disabled text-truncate">
              {page.description}
            </div>
          </div>
        </div>
      </button>
    </li>
  );
}
