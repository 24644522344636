import * as React from "react";
import { ViewerState } from "./ViewerTypes";

export const defaultViewerState: ViewerState = {
  materialPages: [],
  selectedMaterialPage: undefined,
  materialChapters: {
    status: "loading",
    data: [],
  },
};

const ViewerContext = React.createContext<{
  state: ViewerState;
  setState: React.Dispatch<React.SetStateAction<ViewerState>>;
}>({
  state: defaultViewerState,
  setState: (_: ViewerState) => {},
});

export default ViewerContext;
