import useUser from "../useUser";
import useApiEffect from "../useApiEffect";
import { isNumber } from "@Core/Helpers/utils";

import LearningMaterialChaptersService from "@Repos/Services/ChaptersService";

// Types.
import type { Boolbacks, RequestState } from "@Data/Types";
import type { LearningMaterialChapter } from "@Data/Entities/LearningMaterialChapterEntity";

type Props = {
  materialID?: string;

  /**
   * Whether the app is in idle state or not.
   * Defines whether the app will work or show some idle screen.
   */
  isIdle?: boolean;

  /**
   * Method that updates the local request state.
   */
  onChangeRequestState: (newState: RequestState) => void;

  /**
   * Callback in case of a successful fetch.
   */
  onSuccess: Boolbacks<LearningMaterialChapter[]>["onSuccess"];
};

/**
 * Hooks into the LearningMaterialChapter collection.
 *
 * @param {Props} props
 *
 * @author Abdullah-Sajjad026
 *
 * @version 0.0.1
 */
export default function useLearningMaterialChapters({
  materialID,
  isIdle,
  onSuccess,
  onChangeRequestState,
}: Props) {
  const user = useUser();

  useApiEffect(() => {
    const service = new LearningMaterialChaptersService(user.accessToken);

    if (isIdle) return;

    // Guard clause.
    if (!isNumber(materialID)) {
      onChangeRequestState({ status: "erred", message: "404 - Not Found" });
      return;
    }

    // Notice the type coercion.
    service.getChapters<{ data: LearningMaterialChapter[] }>({
      id: materialID,
      onFailure: (message) => {
        // Results in a complete halt.
        onChangeRequestState({ message, status: "erred" });
      },
      onSuccess: ({ data }) => {
        // The request has been successful.
        onChangeRequestState({ status: "loaded" });

        // Time to adapt the data and return.
        onSuccess(data);
      },
    });
  }, [materialID, isIdle]);
}
