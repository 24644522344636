import { Boolbacks } from "@Data/Types";
import { apiService } from "../../service";
import { URL_USER_SERVICE } from "@Core/env";
import { User } from "@Data/Entities/UserEntity";
import { getAuthHeaders, getFormDataFromObject } from "@Core/Helpers/utils";

export default class LearningMaterialChaptersService {
  token: User["accessToken"];

  constructor(token: User["accessToken"]) {
    this.token = token;
  }

  /**
   * Fetch a material's chapters.
   */
  getChapters<T>({
    id,
    limit = 12,
    page = 1,
    order = "asc",
    onSuccess,
    onFailure,
  }: Boolbacks<T> & {
    id: number;
    limit?: number;
    page?: number;
    order?: "asc" | "desc";
  }) {
    apiService.get({
      onSuccess,
      onFailure,
      data: undefined,
      headers: getAuthHeaders(this.token),
      url: `${URL_USER_SERVICE}/contentbuilder/learning-material/fetch-chapters/${id}?page=${page}&limit=${limit}&order=${order}`,
    });
  }

  /**
   * Create a new chapter.
   */
  createChapter<T>({
    content_id,
    title,
    description,
    thumbnail,
    onSuccess,
    onFailure,
  }: Boolbacks<T> & {
    content_id: number;
    title: string;
    description: string;
    thumbnail: File;
  }) {
    const formData = getFormDataFromObject({
      title,
      description,
      thumbnail,
      content_id,
    });
    apiService.post({
      onSuccess,
      onFailure,
      data: formData,
      headers: getAuthHeaders(this.token),
      url: `${URL_USER_SERVICE}/contentbuilder/learning-material/add-chapter`,
    });
  }

  /**
   * Update a chapter.
   */
  updateChapter<T>({
    contentId,
    chapterId,
    title,
    description,
    thumbnail,
    onSuccess,
    onFailure,
  }: Boolbacks<T> & {
    contentId: number;
    chapterId: number;
    title: string;
    description: string;
    thumbnail: File;
  }) {
    const formData = getFormDataFromObject({
      title,
      description,
      content_id: contentId,
      chapter_id: chapterId,
    });

    // if thumbnail is a file, append it to the form data
    if (thumbnail instanceof File) {
      formData.append("thumbnail", thumbnail);
    }

    apiService.post({
      onSuccess,
      onFailure,
      data: formData,
      headers: getAuthHeaders(this.token),
      url: `${URL_USER_SERVICE}/contentbuilder/learning-material/update-chapter`,
    });
  }

  /**
   * Delete a chapter.
   */
  deleteChapter<T>({
    chapterId,
    onSuccess,
    onFailure,
  }: Boolbacks<T> & {
    chapterId: number;
  }) {
    apiService.delete({
      // @ts-ignore
      onSuccess,
      onFailure,
      recordId: chapterId,
      headers: getAuthHeaders(this.token),
      url: `${URL_USER_SERVICE}/contentbuilder/learning-material/delete-chapter`,
    });
  }

  /**
   * Add/Remove a page from chapter
   */
  manageChapterPage<T>({
    action,
    pageId,
    chapterId,
    contentId,
    onSuccess,
    onFailure,
  }: Boolbacks<T> & {
    chapterId: number;
    pageId: number;
    contentId: number;
    action: "add" | "delete";
  }) {
    const formData = getFormDataFromObject({
      api_type: action,
      page_id: pageId,
      chapter_id: chapterId,
      content_id: contentId,
    });

    apiService.post({
      onSuccess,
      onFailure,
      data: formData,
      headers: getAuthHeaders(this.token),
      url: `${URL_USER_SERVICE}/contentbuilder/learning-material/add-chapter-pages`,
    });
  }
}
